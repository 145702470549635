import Axios from 'axios';

const HttpClient = Axios.create({
    baseURL: process.env.VUE_APP_HTTP_CLIENT_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': process.env.VUE_APP_HTTP_CLIENT_ACCESS_CONTROL_ALLOW_ORIGIN,
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
    },
});

export default HttpClient;
