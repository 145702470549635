export const Envs = {
    test: 'test',
    development: 'development',
    staging: 'staging',
    production: 'production',
};

export const Operators = {
    pilot: 'pilot',
    ubiquity: 'ubiquity',
};

export const UserGroup = {
    admin: 'admin',
    supervisor: 'supervisor',
    installer: 'installer',
    all: 'all',
};

export const RequiredFields = {
    [Operators.pilot]: {
        errorMessage: 'Please fill Service ID before you can complete order.',
        fields: ['external_service_id'],
    },
    [Operators.ubiquity]: {
        errorMessage: 'Please fill required fields before you can complete order.',
        fields: [
            'addressbook_id',
            'chr_account_id',
            'chr_integration_account_id',
            'chr_account_id_guid',
            'chr_sales_order_id',
        ],
    },
};
