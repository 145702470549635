<template>
    <div id="app">
        <router-view />
        <Notification class="alert" />
    </div>
</template>

<script>
    // libs and helpers
    import { setAutorizationHeader } from './common/sessionHelper';

    // components
    import Notification from './components/Notification.vue';

    export default {
        name: 'App',

        components: {
            Notification,
        },

        created() {
            setAutorizationHeader();
            this.addAlertForConnectionLoss();
        },

        methods: {
            addAlertForConnectionLoss() {
                window.addEventListener('offline', () => {
                    if (!navigator.onLine) {
                        this.$eventBus.emit('showAlert', {
                            message: 'Error: Internet disconnected',
                            type: Notification.error,
                        });
                    }
                });
            },
        },
    };
</script>

<style lang="scss">
    @import 'assets/scss/base';
    @import 'assets/scss/typography';

    * {
        font-family: $font-family-base;
    }

    .alert {
        position: fixed;
        left: 0;
        bottom: 0;
    }
</style>
