<template>
    <div>
        <div class="duplicate-slot-wrapper">
            <div
                v-if="label"
                class="duplicate-slot-header col-11"
            >
                <div class="duplicate-slot-label">
                    {{ label }}
                </div>
            </div>
            <div v-if="numberOfSlots">
                <template
                    v-for="slotNumber in numberOfSlots"
                    :key="slotNumber"
                >
                    <div class="slot-fields position-relative row page-block">
                        <slot :slotNumber="slotNumber - 1" />

                        <img
                            v-show="showDeleteButton"
                            src="../assets/icons/delete-trash.svg"
                            alt="delete"
                            class="delete-btn col-1"
                            @click="showDeleteModal(slotNumber - 1)"
                            @keypress.enter="showDeleteModal(slotNumber - 1)"
                        >
                    </div>
                </template>
                <span
                    v-if="explanation"
                    class="explanation"
                >
                    {{ explanation }}
                </span>
            </div>
        </div>
        <AppButton
            :label="addButtonLabel"
            class="btn-padding"
            :colorScheme="Colors.transparent"
            :iconClass="Icons.plusBlue"
            :disabled="!numberOfProperties"
            @click="addSlot"
        />
        <AppDialog
            v-model="showModal"
            title="Delete Router ID"
            description="Are you sure you want to delete the Router ID?"
            cancelLabel="Cancel"
            submitLabel="Delete"
            :iconClassRight="true"
            @close="closeDialog"
            @submit="deleteSlot"
        />
    </div>
</template>

<script>
    // libs and helpers
    import { mapActions } from 'vuex';
    import clone from 'lodash.clone';
    import remove from 'lodash.remove';
    import AppDialog from './AppDialog.vue';

    // components
    import AppButton, { Colors, Icons } from './AppButton.vue';

    export default {
        name: 'DuplicateSlot',

        components: {
            AppButton,
            AppDialog,
        },
        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            addButtonLabel: {
                type: String,
                default: '',
            },
            enableDeleteButton: {
                type: Boolean,
                default: false,
            },
            numberOfProperties: {
                type: Number,
                default: 1,
            },
            optional: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            explanation: {
                type: String,
                default: '',
            },
            // prop specific for addresses in account editor
            accountEditor: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue', 'input', 'deletedSlot'],
        data() {
            return {
                Colors,
                Icons,
                showModal: false,
                deleteSlotNum: null,
            };
        },

        computed: {
            numberOfSlots() {
                return this.modelValue.length;
            },
            showDeleteButton() {
                return this.modelValue.length > 1 && this.enableDeleteButton;
            },
        },

        methods: {
            ...mapActions([
                'removeRouterId',
            ]),
            addSlot() {
                const newValue = clone(this.modelValue);
                const newObj = {};

                Object.keys(newValue[0]).forEach(key => {
                    newObj[key] = null;
                });

                newValue[newValue.length] = '';
                this.$emit('input', newValue);
                this.$emit('update:modelValue', newValue);
            },
            closeDialog() {
                this.showModal = false;
            },
            showDeleteModal(slotNumber) {
                this.deleteSlotNum = slotNumber;
                this.showModal = true;
            },
            deleteSlot() {
                this.closeDialog();
                const newValue = clone(this.modelValue);
                const filteredResult = this.modelValue.filter((val, index) => index === this.deleteSlotNum);
                this.removeRouterId(filteredResult[0]);

                remove(newValue, (val, valIndex) => valIndex === this.deleteSlotNum);
                this.$emit('deletedSlot', this.deleteSlotNum);

                // toDo: refactor this!
                if (this.accountEditor) {
                    // third parameter 1 if slot is added
                    // 0  if slot is deleted
                    const isAdded = 0;
                    this.$emit('input', { newValue, isAdded });
                    this.$emit('update:modelValue', { newValue, isAdded });
                } else {
                    this.$emit('input', newValue);
                    this.$emit('update:modelValue', newValue);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_palette.scss';
    @import '~@/assets/scss/_layout.scss';

    .duplicate-slot-wrapper {
        .duplicate-slot-header {
            display: flex;
            justify-content: space-between;
            padding: 0 0 $spacing-xxs;

            .duplicate-slot-label {
                font-size: 0.75rem;
                color: $gray-label;
                font-weight: 600;
                line-height: 1.625rem;
            }
        }

        .delete-btn {
            position: absolute;
            cursor: pointer;
            top: 2.4rem;
            right: -2rem;
            height: 1rem;
        }

        .slot-fields {
            position: relative;
        }
    }
    .page-block {
        background: #fff;
        margin-top: 20px;
        padding: 8px;
        margin-bottom: 16px;
    }

    .explanation {
        line-height: 20px;
        color: $gray-optional-label;
        font-size: 12px;
        font-weight: 500;
    }
    .btn-padding {
        padding-top: 8px;
        :deep(.with-icon-padding) {
            padding-left: 0;
        }
    }
</style>
