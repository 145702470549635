<template>
    <button
        :class="[{ 'small-button': isSmall },
                 { 'with-icon-padding': (iconClass) },
                 { 'disable-padding': disablePadding },
                 { shadow },
                 { clicked },
                 { disabled, control }, colorScheme]"
        :title="tooltip"
        :disabled="disabled"
        class="no-outline display-flex justify-content-center
            align-items-center font-weight-bold text-nowrap text-capitalize"
        @click="$emit('click')"
    >
        <slot>
            <span
                v-if="iconClass"
                :class="['icon', iconClass, { 'icon-margin': !disableIconMargin }]"
            />
            {{ label }}
            <span
                v-if="iconClassRight"
                :class="['icon-right', iconClassRight]"
            />
        </slot>
    </button>
</template>

<script>
    export const Colors = {
        orange: 'orange',
        gray: 'gray',
        transparent: 'transparent',
        white: 'white',
        whiteYellow: 'white-yellow',
        whiteRed: 'white-red',
        whiteBlue: 'white-blue',
        whiteGreen: 'white-green',
        whiteBlack: 'white-black',
        whiteOrange: 'white-orange',
        whiteWithBorder: 'white-blue-border',
        whiteWithShadow: 'white-blue-shadow',
        blue: 'blue',
        default: '',
    };

    export const Icons = {
        plus: 'plus',
        plusBlue: 'plus-blue',
        plusOrange: 'plus-orange',
        ok: 'ok',
        checkmark: 'checkmark',
        checkmarkWhite: 'checkmark-white',
        arrowLeftGray: 'arrow-left-gray',
        arrowRightGray: 'arrow-right-gray',
        edit: 'edit',
        editBlue: 'edit-blue',
        editSmall: 'edit small',
        okOrange: 'ok-orange',
        arrowLeftWhite: 'arrow-left-white',
        arrowLeftBlue: 'arrow-left-blue',
        arrowDownBlue: 'arrow-down-blue',
        arrowUpBlue: 'arrow-up-blue',
        arrowRightWhite: 'arrow-right-white',
        arrowLeftDark: 'arrow-left-dark',
        stop: 'stop',
        start: 'start',
        download: 'download',
        downloadWhite: 'download-white',
        downloadBlue: 'download-blue',
        search: 'search',
        lock: 'lock',
        filter: 'filter',
        default: '',
    };

    export default {
        name: 'AppButton',
        props: {
            label: {
                type: String,
                required: false,
                default: '',
            },
            iconClass: {
                type: String,
                required: false,
                default: Icons.default,
            },
            iconClassRight: {
                type: String,
                required: false,
                default: null,
            },
            disableIconMargin: {
                type: Boolean,
                default: false,
            },
            disablePadding: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            control: {
                type: Boolean,
                default: false,
            },
            colorScheme: {
                type: String,
                default: Colors.default,
            },
            tooltip: {
                type: String,
                default: '',
            },
            isSmall: {
                type: Boolean,
                default: false,
            },
            shadow: {
                type: Boolean,
                default: false,
            },
            clicked: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['click'],
    };
</script>

<style lang="scss" scoped>

    @import '../assets/scss/colors';
    @import '../assets/scss/palette';

    $icon-path: '../assets/icons/';

    button {
        height: 40px;
        line-height: 40px;
        padding:  0 32px;
        border-radius: 20px;
        font-size: 14px;
        border: 0;
        color: white;
        background-color: $navy;

        &.disable-padding {
            padding: 0;
        }

        // color schemes
        &.orange {
            background-color: $orange;
        }

        &.transparent {
            background-color: transparent;
            color: $blue;
        }

        &.white {
            background-color: white;
            color: $blue;

            &.clicked {
                background-color: $white15;
            }

        }

        &.white-yellow {
            background-color: white;
            color: $yellow;
        }

        &.white-orange {
            background-color: white;
            border: 1px solid $orange;
            color: $orange;
        }

        &.gray {
            color: white;
            background-color: $gray;
        }

        &.blue {
            background-color: $blue;
            box-shadow: 0 4px 8px 0 rgba(51, 81, 149, 0.3);
            color: $white;
        }

        &.white-blue-border {
            background-color: $white;
            border: 1px solid $blue;
            color: $blue;
        }

        &.white-blue-shadow {
            box-shadow: 0 4px 4px 0 rgba(51, 81, 149, 0.3);
            background-color: $white;
            color: $blue;
        }

        &:hover:not(.disabled) {
            cursor: pointer;
            background-color: $black;
            color: $whitey;

            &.orange {
                background-color: $orange;
            }

            &.transparent {
                background-color: transparent;
                color: $blue;
            }

            &.white {
                color: $blue;
                background-color: $lighter-gray;

                &.clicked {
                    background-color: $white20;
                }
            }

            &.white-yellow {
                background-color: $yellow-hover;
                color: $yellow;
            }

            &.white-orange {
                background-color: lighten($orange, 40%);
                border: 1px solid $orange;
                color: $orange;
            }

            &.blue {
                background-color: $blue20;
                color: $white;
            }

            &.white-blue-border {
                background-color: $white5;
                color: $blue;
            }

            &.white-blue-shadow {
                background-color: $white5;
                color: $blue;
            }
        }

        &:focus {
            &.blue {
                background-color: $blue40;
                color: $white;
            }

            &.white-blue-border {
                background-color: $white10;
                color: $blue;
            }

            &.white-blue-shadow {
                background-color: $white10;
                color: $blue;
            }

            &.clicked {
                box-shadow: none;
            }
        }

        &:active {
            &.blue {
                background-color: $blue60;
                color: $white;
            }

            &.white-blue-border {
                background-color: $white15;
                color: $blue;
            }

            &.white-blue-shadow {
                background-color: $white15;
                color: $blue;
            }
        }

        &.disabled {
            opacity: 0.5;

            &.gray {
                opacity: 1;
            }
        }

        &.control {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
            padding: 0;
            background-color: white;
            border: 1px solid $light-gray;
            border-radius: 2px;

            &:hover:not(.disabled) {
                background-color: white;
            }

            .icon {
                margin-right: 0;
            }
        }

        &.small-button {
            height: 32px;
            padding: 0 24px;
            max-width: 100%;
            border-radius: 20px;
            line-height: 32px !important;
            font-size: 12px;

            &.control {
                min-height: 32px;
                border-radius: 2px;
            }
        }

        &.shadow {
            box-shadow: 0 2px 6px 0 rgba(51, 81, 149, 0.3);
        }

        .icon, .icon-right {
            width: 24px;
            height: 24px;
            &:before {
                line-height: 0;
                display: flex;
                align-items: center;
                height: 24px;
            }
        }

        .icon.icon-margin {
            margin-right: 8px;
            margin-top: 8px;
        }

        .icon-right {
            margin-left: 8px;
        }

        // icons
        .search:before {
            content: url($icon-path + 'search.png');
        }

        .checkmark:before {
            content: url($icon-path + 'icon-checkmark.svg');
        }

        .checkmark-white:before {
            content: url($icon-path + 'icon-checkmark-white.svg');
        }

        .plus-blue:before {
            content: url($icon-path + 'plus-blue.svg');
        }
    }

    // every button with an icon should have padding-left 24px and padding-right 32px
    .with-icon-padding {
        padding-left: 24px;
        padding-right: 32px;
    }
</style>
