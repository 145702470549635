import { UserGroup } from './constants';

const USER_GROUPS_SESSION_KEY = 'user.group';

// eslint-disable-next-line no-underscore-dangle
let _groups = [];

export { UserGroup };

export const getUserGroup = () => {
    if (_groups.length) {
        return [..._groups];
    }

    const groups = window.localStorage.getItem(USER_GROUPS_SESSION_KEY);

    if (!groups) {
        return [UserGroup.all];
    }

    return JSON.parse(groups);
};

export const setUserGroup = groups => {
    const parseGroup = JSON.stringify(groups);
    window.localStorage.setItem(USER_GROUPS_SESSION_KEY, parseGroup);
    _groups = [...groups];
};

export const isUserGroupOf = group => {
    const groups = getUserGroup();
    if (typeof group === 'string') {
        return groups.includes(group);
    }
    if (Array.isArray(group)) {
        const intersection = groups.filter(x => group.includes(x));
        return !!intersection.length;
    }
    // eslint-disable-next-line no-console
    console.error('group should be string or array');
    return false;
};
