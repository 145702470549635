const exportObj = () => {
    if (typeof window === 'undefined') {
        // node
        return process.env;
    }
    // browser
    return window.process.env;
};

export default exportObj;
