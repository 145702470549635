import HttpClient from '../http/index';

const SESSION_DATA_KEY = 'lfInstallerSessionData';

export function setSessionData(sessionData) {
    localStorage.setItem(SESSION_DATA_KEY, JSON.stringify(sessionData));
}

function deleteSessionData() {
    localStorage.removeItem(SESSION_DATA_KEY);
}

function getSessionData() {
    const sessionData = localStorage.getItem(SESSION_DATA_KEY);

    if (sessionData) {
        return JSON.parse(sessionData);
    }

    return null;
}

function getApiToken() {
    const sessionData = getSessionData();
    const apiToken = sessionData.api_token;

    return apiToken;
}

export function isTokenActive() {
    const sessionData = getSessionData();

    if (sessionData) {
        const startTime = sessionData.token_start;
        const expireSec = sessionData.expire_sec;
        const timeNow = new Date().getTime() / 1000;

        if (startTime && expireSec) {
            return timeNow < (startTime + expireSec);
        }
    }

    return false;
}

export function setAutorizationHeader() {
    if (isTokenActive()) {
        const apiToken = getApiToken();

        HttpClient.defaults.headers.common.Authorization = apiToken;
    } else {
        deleteSessionData();
    }
}
