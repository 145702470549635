<template>
    <div
        v-if="alert.message"
        :class="[alert.type, { invisible: !alert.message }]"
        class="alert alert-dismissible"
    >
        {{ alert.message }}
        <div class="buttons-wrapper">
            <template v-if="alert.buttons">
                <AppButton
                    v-for="(btnItem, index) in alert.buttons"
                    :key="index"
                    :label="btnItem.label"
                    :iconClass="btnItem.iconClass"
                    :colorScheme="btnItem.colorScheme"
                    class="mr-4"
                    @click="emitButton(btnItem.id)"
                />
            </template>
            <button
                type="button"
                class="no-outline close-button"
                @click="onClose"
            />
        </div>
    </div>
</template>

<script>
    import AppButton from './AppButton.vue';

    export const AlertTypes = {
        error: 'alert-danger',
        success: 'alert-success',
        warning: 'alert-warning',
        info: 'alert-info',
    };

    export class Alert {
        message;
        type;
        buttons;

        constructor(
            message,
            type,
            buttons,
        ) {
            this.message = message;
            this.type = type || AlertTypes.error;
            this.buttons = buttons;
        }
    }

    export default {
        name: 'Notification',
        components: { AppButton },

        data() {
            return {
                alert: {
                    message: '',
                    type: AlertTypes.error,
                    buttons: [],
                },
            };
        },

        watch: {
            $route() {
                this.onClose();
            },
            alert: {
                handler(value) {
                    if (value.type === AlertTypes.success) {
                        setTimeout(this.onClose, 2000);
                    }
                },
            },
        },

        created() {
            this.$eventBus.on('showAlert', alertValue => {
                this.alert = new Alert(alertValue.message, alertValue.type, alertValue.buttons);
            });
        },

        methods: {
            emitButton(id) {
                this.$eventBus.emit('buttonClicked', id);
                this.onClose();
            },
            onClose() {
                this.$eventBus.emit('showAlert', {});
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/base';
    @import '../assets/scss/icons';
    @import '../assets/scss/colors';
    @import '../assets/scss/palette';

    .alert {
        display: flex;
        justify-content: space-between;
        align-items: center;

        bottom: 0;

        z-index: 1000000;

        border: 0;
        margin: 0;
        padding: 0 24px 0 28px;

        height: 64px;
        width: 100vw;

        color: white;
        font-weight: bold;

        &.alert-danger {
            background-color: $red;

            .close-button:before {
                content: url("../assets/icons/" + $button-close);
            }
        }

        &.alert-success {
            background-color: $green;

            .close-button:before {
                content: url("../assets/icons/" + $icon-ok);
            }
        }

        &.alert-warning {
            background-color: $yellow;

            .close-button:before {
                content: url("../assets/icons/" + $button-close);
            }
        }

        &.alert-info {
            background-color: $blue;

            .close-button:before {
                content: url("../assets/icons/" + $button-close);
            }
        }

        .buttons-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .close-button {
            border: 0;
            background-color: transparent;
            color: $white;
            margin-left: 24px;

            &:before {
                cursor: pointer;
                vertical-align: middle;
            }
        }

    }
</style>
