import { RequiredFields } from './constants';

export const accountPropertyValidator = (operator, operatorData) => {
    if (!RequiredFields || !Object.keys(RequiredFields).includes(operator)) {
        return {
            errorMessage: `Operator ${operator} is not declared for validation.`,
            isValid: false,
        };
    }
    const { errorMessage, fields } = RequiredFields[operator];
    const validated = {
        isValid: true,
        errorMessage,
    };
    validated.isValid = fields.reduce((isValid, field) =>
        isValid && !!operatorData[field]?.length, validated.isValid);
    return validated;
};

export default accountPropertyValidator;
