/* eslint-disable import/prefer-default-export */
import store from '../store';
import { isUserGroupOf } from './userGroup';
import { isTokenActive } from '../common/sessionHelper';

/**
 * Create feature flag checker based on the feature map configuration.
 *
 * Result object: { featureName: featureCheckerFunction }.
 * 1. Feature is disabled if current env is in the feature disabled list.
 * 2. Feature is disabled if current operator is in operator disabled list.
 * 3. Feature state could be checked by additional validator if available.
 */
export function createFeatureChecker(featuresMap, currentEnv = '') {
    const env = currentEnv || (process.env.VUE_APP_DEPLOY_ENV ?? '');
    const currentOperator = store.getters.getOperator;
    const operator = currentOperator || 'pilot';
    return Object.entries(featuresMap).reduce(
        (result, [featureName, config]) => ({
            ...result,
            [featureName]: param => {
                if (config.disabledEnvs?.includes?.(env)) {
                    return false;
                }
                if (config.disabledOperators?.includes?.(operator)) {
                    return false;
                }
                if (config.allowUserGroups) {
                    if (!isTokenActive()) {
                        return false;
                    }
                    if (!isUserGroupOf(config.allowUserGroups)) {
                        return false;
                    }
                }
                if (config.additionalValidator) {
                    return config.additionalValidator(param);
                }

                return true;
            },
        }),
        {},
    );
}
