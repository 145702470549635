<template>
    <div
        v-if="modelValue"
        role="alertdialog"
        class="dialog-wrap"
        :aria-modal="modelValue"
        aria-labelledby="dialog_label"
        aria-describedby="dialog_desc"
        @click.self="clickOutSide"
        @keypress.enter.self="clickOutSide"
    >
        <div class="content-wrapper">
            <div class="content">
                <h2
                    id="dialog_label"
                    class="title"
                >
                    {{ title }}
                </h2>
                <p
                    v-if="description"
                    id="dialog_desc"
                    class="description"
                >
                    {{ description }}
                </p>
                <slot />
                <div class="buttons">
                    <div class="close-btn">
                        <AppButton
                            :label="cancelLabel"
                            :colorScheme="Colors.whiteWithShadow"
                            @click="closeDialog"
                        />
                    </div>
                    <AppButton
                        :label="submitLabel"
                        :iconClass="Icons.checkmarkWhite"
                        :colorScheme="Colors.blue"
                        @click="onSubmit"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // COMPONENTS
    import AppButton, { Icons, Colors } from './AppButton.vue';

    export default {
        name: 'AppDialog',
        components: {
            AppButton,
        },
        props: {
            modelValue: {
                type: Boolean,
                required: true,
            },
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            allowClickOutside: {
                type: Boolean,
                default: false,
            },
            cancelLabel: {
                type: String,
                default: '',
            },
            submitLabel: {
                type: String,
                default: '',
            },

        },
        emits: ['update:modelValue', 'close', 'submit'],
        data() {
            return {
                Colors,
                Icons,
            };
        },
        methods: {
            onSubmit() {
                this.$emit('submit');
            },
            closeDialog() {
                this.$emit('update:modelValue', false);
                this.$emit('close');
            },
            clickOutSide() {
                if (!this.allowClickOutside) {
                    return;
                }
                this.closeDialog();
            },
        },
    };
</script>

<style lang="scss" scoped>
@import '../assets/scss/palette';

    .dialog-wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5.25rem 0;
        background: rgba(20, 32, 59, 0.9);
        z-index: 200;
        box-sizing: border-box;
        border-radius: 1rem;
        overflow: auto;
    }
    .content-wrapper {
        background: $white;
        margin: 0 auto;
        width: calc(100% - 3rem);
        max-width: 43rem;
        overflow: hidden;
        border-radius: 1rem;
    }
    .content {
        background: $white;
        overflow-y: auto;
        position: relative;
        max-height: calc(100% - 1.5rem);
        padding: 1.5rem;
        min-height: 10rem;
        box-sizing: border-box;
        border-radius: 1rem;
    }
    .description {
        margin-bottom: 1rem;
    }
    .buttons {
        display: flex;
        align-items: center;
        margin-top: 2.5rem;
        justify-content: flex-end;
    }
    .cancel-btn {
        margin-right: 5rem;
    }
    .close-btn {
        padding-right: 2rem;
    }
</style>
