<template>
    <div class="default-page-layout">
        <AppHeader pageTitle="Account search" />
        <div class="page-content">
            <AppInput
                v-model="accountId"
                :invalid="invalidAccountId"
                placeholder="Account ID"
                label="FIND USER WITH ACCOUNT ID"
                errorMessage="Invalid account ID number"
                class="account-input"
                @input="removeErrorMessage"
            />
            <div class="search-button-wrapper">
                <AppButton
                    label="Search"
                    :colorScheme="Colors.blue"
                    :iconClass="Icons.search"
                    @click="onSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // libs and helpers
    import { getAccount } from '../http/user';
    import { mapActions } from 'vuex';

    // components
    import AppHeader from '../components/AppHeader.vue';
    import AppInput from '../components/AppInput.vue';
    import AppButton, { Colors, Icons } from '../components/AppButton.vue';

    const uuidWithoutDashesRegex = /^\s*[a-f0-9]{32}\s*$/i;

    export default {
        name: 'Search',
        components: {
            AppHeader,
            AppInput,
            AppButton,
        },
        data() {
            return {
                accountId: '',
                invalidAccountId: false,
                Colors,
                Icons,
            };
        },
        methods: {
            ...mapActions([
                'setAccountData',
            ]),
            async onSearch() {
                const isAccountIdValid = uuidWithoutDashesRegex.test(this.accountId);
                this.invalidAccountId = !isAccountIdValid;
                if (isAccountIdValid) {
                    try {
                        this.$Progress.start();
                        const result = await getAccount(this.accountId);
                        this.setAccountData(result.data);
                        this.$Progress.finish();
                        this.$router.push({ name: 'accountDetails', params: { accountId: this.accountId } });
                    } catch (error) {
                        this.$Progress.fail();
                        let errorMessage = 'Something went wrong. Please contact LotusFlare.';
                        if (error.response.status === 500) {
                            errorMessage = 'Server error. Please try again.';
                        } else if (error.response.status === 403) {
                            errorMessage = 'Authorization error. Please contact LotusFlare.';
                        } else if (error.response.status === 404) {
                            errorMessage = 'Provided Account ID does not exist, please check your input.';
                        }
                        this.$eventBus.emit('showAlert', {
                            message: errorMessage,
                        });
                    }
                }
            },
            removeErrorMessage() {
                this.invalidAccountId = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .account-input {
        margin-bottom: 16px;
    }

    .search-button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
</style>
