import HttpClient from './index';

const servicePath = '/api/v3/provider/osa';

/**
 * @param serviceId
 * @param ontId
 * @return {AxiosPromise}
 */
export function startProvision(accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/provision_resource`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
        },
    });
}

/**
 * @param taskId
 * @return {AxiosPromise}
 */
export function checkProvisionStatus(accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/get_provisioning_status`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
        },
    });
}

export default {
    startProvision,
    checkProvisionStatus,
};
