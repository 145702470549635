import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Search from '../views/Search.vue';
import AccountDetails from '../views/AccountDetails.vue';
import EditAccount from '../views/EditAccount.vue';
import Scanning from '../views/Scanning.vue';

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
    },
    {
        path: '/account-details/:accountId',
        name: 'accountDetails',
        component: AccountDetails,
        props: true,
    },
    {
        path: '/account-edit',
        name: 'editAccount',
        component: EditAccount,
    },
    {
        path: '/ont-scanning',
        name: 'ontScanning',
        component: Scanning,
    },
    {
        path: '/eero-scanning',
        name: 'eeroScanning',
        component: Scanning,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
