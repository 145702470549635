import HttpClient from './index';

const servicePath = '/api/v3/user';
const osstmofiberPath = '/api/v3/osstmofiber';

/**
 * @param email
 * @param secret
 * @return {AxiosPromise}
 */
export const lfAuth = token => HttpClient({
    method: 'POST',
    url: `${servicePath}/social_authentication`,
    headers: {
        'Content-Type': 'application/json',
    },
    data: {
        operator_name: process.env.VUE_APP_LF_OPERATOR,
        application_id: process.env.VUE_APP_LF_APP_ID,
        social_network: 'azure',
        social_token: token,
    },
});

/**
 * @param accountId
 * @return {AxiosPromise}
 */
export function getAccount(accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/get_account`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
        },
    });
}

export function updateResourceMap(externalServiceId, accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/update_resource_map`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
            resource_map: {
                external_service_id: externalServiceId,
            },
        },
    });
}

export function updateDeviceIds(resources, accountId) {
    return HttpClient({
        method: 'POST',
        url: `${osstmofiberPath}/update_device_ids`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
            ...resources,
        },
    });
}

export default {
    lfAuth,
    getAccount,
    updateResourceMap,
    updateDeviceIds,
};
