import App from './App.vue';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import VueProgressBar from '@aacassandra/vue3-progressbar';

import eventBus from './eventBus';

const options = {
    color: '#ff5a27',
    failedColor: '#d32a26',
    thickness: '5px',
    autoFinish: true,
};

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueProgressBar, options);

app.config.globalProperties.$eventBus = eventBus;

app.mount('#app');
