import HttpClient from './index';

const servicePath = '/api/v3/orderoe';

/**
 * @param accountId
 * @return {AxiosPromise}
 */
export function getOrderEntities(accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/get_order_entities`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
            sub_orders_disabled: true,
        },
    });
}

/**
 * @param accountId
 * @param orderId
 * @return {AxiosPromise}
 */
export function activateOrder(accountId) {
    return HttpClient({
        method: 'POST',
        url: `${servicePath}/activate_order`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: 2,
        },
    });
}

export default {
    getOrderEntities,
    activateOrder,
};
