<template>
    <div>
        <label class="label">
            {{ label }}
            <div
                class="switch"
                :class="{ small, disabled }"
                data-test-id="toggle-label"
            >
                <input
                    type="checkbox"
                    :checked="modelValue"
                    :disabled="disabled"
                    data-test-id="toggle-input"
                    @change="e => onChange(e.target.checked)"
                >
                <span
                    class="slider"
                    :class="{ small, disabled }"
                />
            </div>

        </label>
    </div>
</template>

<script>
    export default {
        name: 'AppToggle',
        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            small: {
                type: Boolean,
                required: false,
                default: true,
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
        },
        emits: ['update:modelValue', 'input'],
        methods: {
            onChange(value) {
                if (!this.disabled) {
                    this.$emit('update:modelValue', value);
                    this.$emit('input', value);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/_palette.scss';
    @import '../assets/scss/_layout.scss';
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.75rem;
      color: $gray-label;
      font-weight: 600;
      line-height: 1.625rem;
      padding-bottom: $spacing-xxs;
    }
    .switch {
      margin-left: $spacing-xs;
      position: relative;
      display: inline-block;
      width: 37px;
      height: 20px;
      transform: translateY(25%);

      &.small {
        width: 30px;
        height: 16px;
      }
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #e4e4e4;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 20px;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }

      &.disabled {
        background-color: white;
        border: 2px solid #eeeeee;

        &:before {
          background-color: #eeeeee;
          height: 14px;
          width: 14px;
          left: 2px;
          bottom: 1px;
        }
      }

      &.small {
        &:before {
          height: 12px;
          width: 12px;
          left: 2px;
          bottom: 2px;
          border-radius: 100%;
        }

        &.disabled {
          &:before {
            height: 10px;
            width: 10px;
            left: 1px;
            bottom: 1px;
          }
        }
      }
    }

    input:checked + .slider {
      background-color: #e1e6f0;
      width: 37px;

      &:before {
        background-color: #335195;
      }

      &.small {
        width: 30px;
      }
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #e5e5e5;
    }

    input:checked + .slider:before {
      transform: translateX(17px);
    }

    input:checked + .slider.small:before {
      transform: translateX(13px);
    }
</style>
