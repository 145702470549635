<template>
    <div class="page-header-wrap">
        <div
            :class="{ 'new-design': newDesign }"
            class="page-header"
        >
            <h4
                :class="{ 'new-design': newDesign }"
                class="heading"
            >
                {{ headerTitle }}
            </h4>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppHeader',
        props: {
            isSearchEnabled: {
                type: Boolean,
                default: false,
            },
            actionsVisible: {
                type: Boolean,
                default: false,
            },
            pageTitle: {
                type: String,
                default: null,
            },
            newDesign: {
                type: Boolean,
                default: false,
            },
            languageSupport: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {};
        },

        computed: {
            headerTitle() {
                return (this.pageTitle) ? this.pageTitle : this.$route.name;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/palette';
    /*@import '../../assets/scss/sidebar';*/

    .page-header {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 68px;
        padding: 14px 16px;
        box-shadow: $box-shadow;
        background-color: $white;
        line-height: 1;
        z-index: 10;
        box-sizing: border-box;

        &.new-design {
            font-weight: 600;
        }

        &-wrap {
            height: 68px;
        }
    }

    .actions-wrapper {
        display: flex;
        flex: 0 0 40%;
        justify-content: flex-end;
        align-items: center;
    }

    .heading {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 600;
        color: #021b23;

        &.new-design {
            color: $gray-label;
            font-size: 22px;
        }
    }

    .languages-wrapper {
        margin-right: 30px;
    }

    .language {
        width: 18px;
        height: 18px;
        padding: 0 10px;
        font-size: 0.812rem;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $gray30;
        cursor: pointer;

        &.active {
            color: $blue;
        }

        &:hover {
            background-color: $white5;
            color: $blue;
        }
    }
</style>
