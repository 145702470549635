import { createFeatureChecker } from '@/utils/createFeatureChecker';
import { Envs, Operators, UserGroup } from '@/utils/constants';
import env from '@/config/environmentConfig';

/**
 * Feature map config
 * @example
 *  features: {
 *      disabledEnvs: [],
 *      disabledOperators: [],
 *      allowUserGroups: []
 *      additionalValidator: (a: number) => a === 1
 *  },
 * },
 */

const featuresMap = {
    isUbiquity: {
        disabledEnvs: [Envs.production],
        disabledOperators: [Operators.pilot],
    },
    userGroupTest: {
        allowUserGroups: [
            UserGroup.all,
        ],
    },
};

/**
 * Feature flag checker based on feature map config
 * @property featuresMap
 */
export const featureFlag = createFeatureChecker(featuresMap, env.VUE_APP_DEPLOY_ENV);
