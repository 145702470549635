<template>
    <div
        :class="{ 'optional-no-label-wrapper': optionalNoLabel }"
        class="input-wrapper"
    >
        <div
            :class="{ 'optional-no-label mb-1': optionalNoLabel }"
            class="label-wrapper"
        >
            <label
                v-if="label"
                :for="inputId"
                class="label"
            >
                {{ label }}
            </label>
            <span
                v-if="optional"
                class="optional align-self-center"
            >
                {{ $i18n.t('generic.optional') }}
            </span>
        </div>
        <div
            :class="{ 'optional-no-label-content': optionalNoLabel, verified }"
            class="content"
        >
            <input
                :id="inputId"
                :value="modelValue"
                :class="{
                    large,
                    error: invalid,
                    'error-focused': invalid && modelValue !== '',
                    'selected-focused': !invalid && modelValue !== '',
                }"
                :disabled="disabled || verified"
                :placeholder="placeholder"
                :type="type"
                :min="min"
                :max="max"
                :step="step"
                @input="onInput"
                @change="onChange"
                @keyup="onKeyup"
            >
        </div>
        <div
            v-if="invalid && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
    import { uuidV4 } from '@/common/utils';
    import floor from 'lodash.floor';

    export default {
        name: 'AppInput',
        props: {
            modelValue: {
                type: [String, Number],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            large: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'text',
            },
            optional: {
                type: Boolean,
                default: false,
            },
            invalid: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: 100000000,
            },
            step: {
                type: Number,
                default: 1,
            },
            roundNumber: {
                type: Boolean,
                default: false,
            },
            notAllowZero: {
                type: Boolean,
                default: false,
            },
            disablePadding: {
                type: Boolean,
                default: false,
            },
            optionalNoLabel: {
                type: Boolean,
                default: false,
            },
            id: {
                type: String,
                default: '',
            },
            errorMessage: {
                type: String,
                required: false,
                default: '',
            },
            verified: {
                type: Boolean,
                default: false,
            },
            scale: {
                type: Number,
                default: null,
            },
        },
        emits: ['input', 'update:modelValue', 'keyup', 'change'],
        computed: {
            inputId() {
                return this.id || uuidV4();
            },
        },
        methods: {
            onInput(e) {
                const { value } = e.target;
                const oldValue = this.modelValue;

                if (this.roundNumber) {
                    const divider = 1 / this.step;
                    const formattedValue = Math.round(value * divider) / divider;
                    this.$emit('input', formattedValue);
                    this.$emit('update:modelValue', formattedValue);
                } else if (this.notAllowZero && (value === '' || value === '0')) {
                    this.$emit('input', oldValue);
                    this.$emit('update:modelValue', oldValue);
                } else if ((this.scale || this.scale === 0) && value % 1) {
                    e.target.value = floor(value, this.scale);
                    this.$emit('input', floor(value, this.scale));
                    this.$emit('update:modelValue', floor(value, this.scale));
                } else {
                    this.$emit('input', value);
                    this.$emit('update:modelValue', value);
                }
            },
            onChange(event) {
                this.$emit('change', event.target.value);
            },
            onKeyup(event) {
                this.$emit('keyup', event);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/_palette.scss';
    @import '../assets/scss/_layout.scss';

    .input-wrapper {
        align-items: center;
        color: $gray-label;

        .label-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.75rem;
            color: $gray-label;
            font-weight: 600;
            line-height: 1.625rem;
            padding-bottom: $spacing-xxs;
        }

        .content {
            position: relative;
            display: flex;
            align-items: center;
            flex: 0 1 80%;

            input {
                position: relative;
                width: 100%;
                border-radius: 8px;
                padding: 6px 8px;
                outline: none;
                // name $gray-toggle-disabled will be changed soon
                border: 1px solid $gray-toggle-disabled;
                line-height: 20px;
                font-size: 0.875rem;
                color: $gray-label;

                &.large {
                    width: 100%;
                    max-width: 100%;
                    padding: 6px 12px;
                }

                &:focus {
                    border-color: $blue;
                    color: $gray70;
                }

                &:disabled {
                    background-color: $input-background-disabled;
                    border: 1px solid $input-border-disabled;
                    color: $gray30;
                }

                &[invalid] {
                    border-color: $red;
                }

                // reverting to old error validation till we get new requirmenets
                &.error {
                    border-color: $red;
                    color: $gray70;
                }
            }

            &.verified {
                &:after {
                    content: '';
                    position: absolute;
                    top: $spacing-xs;
                    right: $spacing-xs;
                    width: 1rem;
                    height: 1rem;
                    background: url('../assets/icons/input-verified.svg');
                }

                input {
                    color: $gray-label;
                    padding-right: $spacing-xl;
                    background-color: $green10;
                    border-color: $green15;
                }
            }
        }

        .optional {
            font-size: 0.75rem;
            font-style: italic;
            color: $gray-optional-label;
        }

        .optional-no-label {
            justify-content: flex-end;
            align-self: flex-end;
        }

        .error-message {
            padding-top: $spacing-xs;
            font-size: 0.75rem;
            color: $red;
        }
    }

    .optional-no-label-wrapper {
        display: flex;
        flex-direction: column;
        top: -0.75rem;
    }

    .optional-no-label-content {
        align-self: flex-end;
        width: inherit;
    }

    ::placeholder {
        font-style: italic;
        color: $status-gray;
    }

    :deep(.multiselect__placeholder) {
        margin-bottom: 0;
    }
</style>
