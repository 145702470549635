<template>
    <div class="text-button-wrapper">
        <span
            ref="textButton"
            class="text-button"
            tabindex="0"
            role="link"
            :aria-label="label"
            data-testid="text-button-btn"
            @click="click($event)"
            @keypress.enter="click($event)"
        >
            {{ label }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'TextButton',
        props: {
            label: {
                type: String,
                required: true,
            },
        },
        emits: ['click'],
        methods: {
            click(event) {
                this.$emit('click', event);
            },
        },
    };
</script>

<style scoped lang="scss">
    .text-button-wrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
    }

    .text-button {
        display: flex;
        align-items: center;
        -webkit-tap-highlight-color: transparent;
        font-family: 'TeleNeo-Medium', sans-serif;
        color: #335195;
        font-size: 0.875rem;
        line-height: 1.625rem;
        letter-spacing: -0.01rem;
        width: fit-content;
        transition-timing-function: cubic-bezier(.25, .1, .25, 1);
        transition: color 0.5s;
        position: relative;
    }
</style>
