<template>
    <div class="edit-account-section default-page-layout">
        <AppHeader pageTitle="Add Account Details" />
        <div class="page-content">
            <AppInput
                v-model="userName"
                :verified="true"
                label="Name"
            />
            <AppInput
                v-model="accountData.account_id"
                :verified="true"
                label="Account ID"
            />
            <AppInput
                v-model="v$.inputsValues.serviceId.$model"
                :invalid="v$.inputsValues.serviceId.$error"
                label="Service ID"
                placeholder="Add service ID"
                errorMessage="Service ID should be up to 10 digits numeric value"
            />
            <div class="page-block">
                <AppInput
                    v-model="v$.inputsValues.ontId.$model"
                    :invalid="v$.inputsValues.ontId.$error"
                    label="ONT ID"
                    placeholder="Add ONT ID"
                    errorMessage="ONT ID should be a 6 to 12 digits alpha-numeric value"
                />
                <div class="page-section">
                    <img src="../assets/icons/scan.png" alt="Scan ONT">
                    <TextButton
                        label="Scan ONT ID"
                        tabindex="0"
                        @click="navigateToONTScanning"
                    />
                </div>
            </div>

            <AppToggle
                v-model="enableRouterIds"
                class="toggle-btn"
                label="Router ID"
            />
            <DuplicateSlot
                v-model="inputsValues.routerIds"
                :enableDeleteButton="true"
                :optional="true"
                addButtonLabel="Add New router ID"
                class="w-100 kay-value-pairs"
                @input="triggerRouterIdsValidation"
            >
                <template #default="{ slotNumber }">
                    <AppInput
                        v-model="v$.inputsValues.routerIds.$model[slotNumber]"
                        :invalid="isSpecificRouterIdInvalid(slotNumber)"
                        :disabled="!enableRouterIds"
                        :label="`Router ID ${slotNumber + 1}`"
                        placeholder="Add router ID"
                        errorMessage="Router ID should be an alpha-numeric value"
                        @input="triggerRouterIdsValidation"
                    />
                    <div v-if="enableRouterIds" class="page-section">
                        <img src="../assets/icons/scan.png" alt="Scan Router ID">
                        <TextButton
                            label="Scan Router ID"
                            tabindex="0"
                            @click="navigateToEeroScanning"
                        />
                    </div>
                </template>
            </DuplicateSlot>
            <div class="page-footer">
                <AppButton
                    label="Cancel"
                    :colorScheme="Colors.whiteWithShadow"
                    @click="onCancel"
                />
                <AppButton
                    label="Save"
                    :iconClass="Icons.checkmarkWhite"
                    :colorScheme="Colors.blue"
                    :disabled="!isSavingEnabled"
                    @click="onSave"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // libs and helpers
    import { mapActions, mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import {
        minLength,
        maxLength,
        alphaNum,
        numeric,
        required,
    } from '@vuelidate/validators';

    // components
    import AppHeader from '../components/AppHeader.vue';
    import AppButton, { Icons, Colors } from '../components/AppButton.vue';
    import AppInput from '../components/AppInput.vue';
    import TextButton from '../components/TextButton.vue';
    import DuplicateSlot from '../components/DuplicateSlot.vue';
    import { AlertTypes } from '../components/Notification.vue';
    import AppToggle from '../components/AppToggle.vue';

    export default {
        name: 'EditAccount',
        components: {
            AppHeader,
            AppButton,
            AppInput,
            DuplicateSlot,
            AppToggle,
            TextButton,
        },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                inputsValues: {
                    serviceId: '',
                    ontId: '',
                    routerIds: [''],
                },
                enableRouterIds: true,
                Icons,
                Colors,
            };
        },
        validations: {
            inputsValues: {
                serviceId: {
                    numeric,
                    minLength: minLength(1),
                    maxLength: maxLength(10),
                    required,
                },
                ontId: {
                    alphaNum,
                    minLength: minLength(6),
                    maxLength: maxLength(12),
                },
                routerIds: {
                    $each: {
                        alphaNum,
                    },
                },
            },
        },
        computed: {
            ...mapGetters([
                'getUserName',
                'getScannedRouterIds',
                'getScannedOntId',
                'getServiceId',
            ]),
            accountData() {
                return this.$store.state.accountData;
            },
            userName() {
                return this.getUserName;
            },
            stateRouterIds() {
                if (this.getScannedRouterIds && Array.isArray(this.getScannedRouterIds)) {
                    return [...this.getScannedRouterIds];
                }
                return [''];
            },
            accountPropertiesRouterIds() {
                if (this.accountData
                    && this.accountData.properties
                    && Array.isArray(this.accountData.properties.router_ids)
                    && this.accountData.properties.router_ids.length
                ) {
                    return [...this.accountData.properties.router_ids];
                }
                return [''];
            },
            isRouterIdInvalid() {
                for (const routerId of this.inputsValues.routerIds) {
                    if (routerId && !this.isAlphaNumeric(routerId)) {
                        return true;
                    }
                }
                return false;
            },
            isSavingEnabled() {
                return !this.v$.inputsValues.serviceId.$errors.length
                    && !this.v$.inputsValues.ontId.$errors.length
                    && !this.isRouterIdInvalid;
            },
        },
        created() {
            if (this.accountData && this.accountData.properties) {
                this.inputsValues = {
                    serviceId: this.accountData.properties.external_service_id || '',
                    ontId: this.getScannedOntId || this.accountData.properties.ont_id || '',
                    routerIds: this.stateRouterIds.length > 0 ? this.stateRouterIds : this.accountPropertiesRouterIds,
                };
            }
        },
        mounted() {
            this.v$.inputsValues.serviceId.$touch();
        },
        methods: {
            ...mapActions([
                'resetOntAndRouterId',
            ]),
            onCancel() {
                this.resetOntAndRouterId();
                this.$router.push({ name: 'accountDetails', params: { accountId: this.accountData.account_id } });
            },
            triggerRouterIdsValidation() {
                this.v$.inputsValues.routerIds.$touch();
            },
            navigateToONTScanning() {
                this.$router.push({ name: 'ontScanning' });
            },
            navigateToEeroScanning() {
                this.$router.push({ name: 'eeroScanning' });
            },
            isAlphaNumeric(value) {
                return value.match(/^([a-zA-Z0-9]+)$/);
            },
            isSpecificRouterIdInvalid(slotNumber) {
                if (!this.inputsValues.routerIds[slotNumber]) {
                    return false;
                }
                return !this.isAlphaNumeric(this.inputsValues.routerIds[slotNumber]);
            },
            async onSave() {
                if (!this.v$.$error) {
                    try {
                        const filterOutEmpty = this.inputsValues.routerIds.filter(id => id);
                        this.inputsValues.routerIds = filterOutEmpty.length ? filterOutEmpty : [''];
                        // actually updating the values
                        this.$Progress.start();
                        // change the package to only contain non-empty strings
                        const updatePackage = {};
                        if (this.inputsValues.serviceId && this.inputsValues.serviceId !== this.getServiceId) {
                            updatePackage.serviceId = this.inputsValues.serviceId;
                        }
                        updatePackage.ontId = (this.inputsValues.ontId) || (this.accountData.properties.ont_id);
                        updatePackage.routerIds = filterOutEmpty;

                        await this.$store.dispatch('updateResourceMap', updatePackage);

                        this.$eventBus.emit('showAlert', {
                            message: 'Account information updated succesfully',
                            type: AlertTypes.success,
                        });

                        this.$Progress.finish();

                        setTimeout(() => {
                            this.$router.push({ name: 'accountDetails', params: { accountId: this.accountData.account_id } });
                        }, 2000);
                    } catch (error) {
                        const errorCode = error.response?.data?.code;
                        const module = error.response?.data?.module;
                        let errorMessage = 'Unknown error, please contact LotusFlare.';
                        if (error.response.status === 500) {
                            errorMessage = 'Server error. Please try again.';
                        } else if (error.response.status === 403) {
                            errorMessage = 'Authorization error. Please contact LotusFlare.';
                        } else if (error.response.status === 404) {
                            errorMessage = 'Provided Account ID does not exist, please check your input.';
                        } else if (error.response.status === 409) {
                            errorMessage = 'One of provided ID already used, please check your input.';
                        } else if (errorCode === 67 && module === 'OSS_TMOFIBER') {
                            errorMessage = 'Duplicate Service ID found.';
                        }
                        this.$eventBus.emit('showAlert', {
                            message: errorMessage,
                        });
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/colors';
    @import '../assets/scss/palette';

    .edit-account-section {
        :deep(.page-content) {
            position: absolute;
            padding-bottom: 80px;
            left: 0;
            right: 0;
            overflow: auto;
        }
    }
    .breadcrumbs-wrapper {
        display: flex;
        margin-bottom: 24px;

        .breadcrumbs-item {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            font-weight: 600;
            color: $blue;
            padding-right: 30px;
            cursor: pointer;

            &:after {
                position: absolute;
                right: 4px;
                top: 5px;
                content: "";
                background: url("../assets/icons/icon-arrow-right.svg");
                width: 16px;
                height: 16px;
            }

            &.active {
                opacity: 0.5;
                padding-right: 8px;
                cursor: auto;

                &:after {

                    background: none;
                }
            }
        }
    }

    section {
        margin-bottom: 38px;

        .section-title {
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }

        .section-content {
            padding: 8px 16px 12px;
            border-radius: 8px;
            background-color: $white;
            display: grid;
            grid-template-columns: 95px 1.5fr;
            grid-template-rows:  1fr minmax(0, 2fr);
            grid-gap: 1rem;

            &.provision-section {
                padding: 24px;
                display: block;

                .provision-section-text {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.75;
                    color: #8597bf;
                    text-transform: uppercase;
                    margin-bottom: 16px;
                }
            }

            .content-value {
                word-wrap: break-word;
                word-break: break-all;
            }
        }

        .content-key {
            font-size: 12px;
            font-weight: 600;
        }

        .content-value {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .kay-value-pairs {
        max-width: 90%;
    }
    .page-block {
        background: #fff;
        margin-top: 20px;
        padding: 8px;
    }
    .page-section {
        display: flex;
        align-items: center;
        margin-top: 15px;
        img {
            width: 16px;
            height: 16px;
            margin-right: 11px;
        }
    }
    .toggle-btn {
        padding-top: 16px;
    }
</style>
