<template>
    <div>
        <div class="default-page-layout">
            <AppHeader :pageTitle="pageTitle" />
            <div class="scan-desc">
                {{ description }}
            </div>
            <div id="scandit-barcode-picker" style="max-height: 615px" />
            <button @click="onCancel">
                Cancel
            </button>
        </div>
        <AppDialog
            v-model="showModal"
            :title="modalTitle"
            :description="modalDesc"
            cancelLabel="Try again"
            submitLabel="Save"
            @close="closeDialog"
            @submit="saveVal"
        >
            <AppInput
                v-model="scannedVal"
                :label="modalTitle"
            />
        </AppDialog>
    </div>
</template>

<script>
    import AppHeader from '../components/AppHeader.vue';
    import AppDialog from '../components/AppDialog.vue';
    import AppInput from '../components/AppInput.vue';
    import { mapActions } from 'vuex';

    export default {
        name: 'TextButton',
        components: {
            AppHeader,
            AppDialog,
            AppInput,
        },
        data() {
            return {
                ScanditSDK: null,
                scanditStarted: false,
                scannedVal: null,
                barcodePicker: null,
                license: process.env.VUE_APP_SCANDIT_LICENSE_KEY,
            };
        },
        computed: {
            isONTPage() {
                return this.$route.name === 'ontScanning';
            },
            isEeroPage() {
                return this.$route.name === 'eeroScanning';
            },
            pageTitle() {
                return this.isONTPage ? 'Scan ONT ID' : 'Scan Router ID';
            },
            description() {
                return this.isONTPage ? 'Please scan the QR code to add ONT ID.' : 'Please turn your phone to scan the barcode to add router ID.';
            },
            showModal() {
                return this.scannedVal !== null;
            },
            modalTitle() {
                return this.isONTPage ? 'ONT ID' : 'Router ID';
            },
            modalDesc() {
                return this.isONTPage ? 'The ONT ID has been scanned.' : 'The router ID has been scanned.';
            },
        },
        async mounted() {
            // helpful dynamic imports for SSR
            // https://support.scandit.com/hc/en-us/articles/4417247434386-How-to-use-the-WebSDK-in-NextJS-React-with-SSR-
            this.ScanditSDK = await require('scandit-sdk');
            await this.ScanditSDK.configure(this.license, {
                engineLocation: process.env.VUE_APP_SCANDIT_URL,
            });

            if (this.ScanditSDK) this.scanditSDKLoading = true;

            await this.ScanditSDK.BarcodePicker.create(document.getElementById('scandit-barcode-picker'), {
                vibrateOnScan: true,
            }).then(barcodePicker => {
                this.barcodePicker = barcodePicker;
            });
            this.initializeScandit();
        },
        methods: {
            ...mapActions([
                'setScannedOntId',
                'setScannedRouterId',
            ]),
            initializeScandit() {
                try {
                    this.barcodePicker.resumeScanning();
                    const barcodeSymbologies = Object.values(this.ScanditSDK.Barcode.Symbology).filter(el =>
                        el !== 'toHumanizedName' && el !== 'toJSONName' && typeof el === 'string');
                    const scanSettings = new this.ScanditSDK.ScanSettings({
                        enabledSymbologies: barcodeSymbologies,
                        codeDuplicateFilter: 1000, // Minimum delay between duplicate results
                    });

                    this.barcodePicker.applyScanSettings(scanSettings);

                    this.barcodePicker.on('scan', scanResult => scanResult.barcodes.reduce(
                        (string, barcode) => {
                            if (this.ScanditSDK.Barcode.Symbology.toHumanizedName(barcode.symbology) === 'QR') {
                                if (['http://', 'https://', 'ftp://'].some(protocol => barcode.data.startsWith(protocol))) {
                                    const regex = /[?&]([^=#]+)=([^&#]*)/g;
                                    const url = barcode.data;
                                    const params = {};
                                    let match;
                                    // eslint-disable-next-line no-cond-assign
                                    while ((match = regex.exec(url))) {
                                        // eslint-disable-next-line prefer-destructuring
                                        params[match[1]] = match[2];
                                    }
                                    barcode.data = `${params.fs.substring(params.fs.length - 6)}`;
                                }
                            }
                            this.scannedVal = `${barcode.data}`;
                            this.barcodePicker.pauseScanning(true);
                            return this.scannedVal;
                        },
                        '',
                    ));
                } catch (e) {
                    alert(`Failed to launch Scandit due to error: ${e}`);
                }
            },
            onCancel() {
                this.barcodePicker.pauseScanning(true);
                this.$router.go(-1);
            },
            closeDialog() {
                this.scannedVal = null;
                this.initializeScandit();
            },
            saveVal() {
                if (this.isONTPage) {
                    this.setScannedOntId(this.scannedVal);
                }
                if (this.isEeroPage) {
                    this.setScannedRouterId(this.scannedVal);
                }
                this.$router.push({ name: 'editAccount' });
            },
        },
    };
</script>

<style scoped lang="scss">
@import '../assets/scss/palette';
.scan-desc {
    padding: 24px 0 24px 16px;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
}
button {
        margin:auto;
        display:block;
        margin-top: 4rem;
        height: 2.5rem;
        line-height: 2.5rem;
        padding:  0 2rem;
        border-radius: 1.25rem;
        font-size: 0.875rem;
        border: 0;
        box-shadow: 0 0.25rem 0.25rem 0 rgba(51, 81, 149, 0.3);
        background-color: $white;
        color: $blue;
    &:hover:not(.disabled) {
        cursor: pointer;
        background-color: $white5;
        color: $blue;
    }
    &:focus {
        background-color: $white10;
        color: $blue;
    }

    &:active {
        background-color: $white15;
        color: $blue;
    }
}
</style>
