<template>
    <div class="default-page-layout">
        <AppHeader pageTitle="Account Details">
            <AppButton
                v-if="!isUbiquity"
                data-test-id="account-edit-btn"
                label="Edit Account"
                :colorScheme="Colors.whiteWithShadow"
                :iconClass="Icons.checkmark"
                @click="onEditAccountClick"
            />
        </AppHeader>
        <div class="page-content">
            <div class="breadcrumbs-wrapper">
                <div
                    class="breadcrumbs-item"
                    @click="goToSearch"
                    @keypress.enter="goToSearch"
                >
                    Search
                </div>
                <div class="breadcrumbs-item active">
                    Account details
                </div>
            </div>
            <section>
                <div class="section-title">
                    {{ detailsTitle }}
                </div>
                <div class="section-content">
                    <div class="content-key">
                        Name
                    </div>
                    <div class="content-value">
                        {{ name }}
                    </div>
                    <div class="content-key">
                        Account ID
                    </div>
                    <div class="content-value">
                        {{ accountId }}
                    </div>
                    <div class="content-key">
                        Offer
                    </div>
                    <div class="content-value">
                        {{ orderName }}
                    </div>
                    <div class="content-key">
                        Service ID
                    </div>
                    <div class="content-value">
                        {{ formattedServiceId }}
                    </div>
                    <div class="content-key">
                        Ont ID
                    </div>
                    <div class="content-value">
                        {{ formattedOntId }}
                    </div>
                    <template v-for="(routerId, index) in getRouterIds" :key="`${routerId}_${index}`">
                        <div class="content-key">
                            {{ (index === 0) ? routerIdsTitle : ' ' }}
                        </div>
                        <div class="content-value">
                            {{ routerId }}
                        </div>
                    </template>
                </div>
            </section>
            <section v-if="isUbiquity">
                <div class="section-title">
                    Links to external tools
                </div>
                <div class="section-content provision-section">
                    <div class="provision-section-text">
                        <b>Circuit builder assignment view:</b>
                        This page will show all equipment assigned to the service location.
                    </div>
                    <AppButton
                        data-test-id="builder-btn"
                        label="Circuit builder assignment view"
                        :colorScheme="Colors.whiteWithShadow"
                        @click="goToBuilder"
                    />
                    <div class="provision-section-text provision-section-text__with-margin">
                        <b>Omnia360 CPE view:</b>
                        This page will allow a technician to assign CPE equipment to an account and service
                    </div>
                    <AppButton
                        data-test-id="cpe-btn"
                        label="Omnia360 CPE view"
                        :colorScheme="Colors.whiteWithShadow"
                        @click="goToCPEView"
                    />
                </div>
            </section>
            <section>
                <div class="section-title">
                    Provision Device
                </div>
                <div class="section-content-wrapper">
                    <div class="section-content provision-section">
                        <div class="provision-section-text">
                            Provision service ID and ONT ID to the customer
                        </div>
                        <AppButton
                            label="Provision"
                            :colorScheme="Colors.whiteWithShadow"
                            :iconClass="Icons.checkmark"
                            :disabled="!provisioningEnabled"
                            @click="onProvisionClick"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div class="section-title">
                    Check Provisioning Status
                </div>
                <div class="section-content provision-section">
                    <div class="provision-section-text">
                        Check the provision status by clicking a button below
                    </div>
                    <AppButton
                        label="Check"
                        :disabled="!externalTaskId"
                        :colorScheme="Colors.whiteWithShadow"
                        @click="onCheckProvisionClick"
                    />
                </div>
            </section>
            <section>
                <div class="section-title">
                    Mark order as filled
                </div>
                <div class="section-content provision-section">
                    <div class="provision-section-text">
                        In understand that the user with account id
                    </div>
                    <AppInput
                        :value="getAccountData.account_id"
                        :disabled="true"
                        class="account-id-for-order"
                    />
                    <div class="provision-section-text">
                        Will be billed for this service
                    </div>
                    <AppButton
                        label="Complete Order"
                        :colorScheme="Colors.blue"
                        :iconClass="Icons.checkmarkWhite"
                        :disabled="completeOrderButtonDisabled"
                        @click="onCompleteOrderClick"
                    />
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    // libs and helpers
    import { mapActions, mapGetters } from 'vuex';
    import { getOrderEntities, activateOrder } from '../http/order';
    import { getAccount } from '../http/user';
    import { startProvision, checkProvisionStatus } from '../http/pilot-fiber';
    import { featureFlag } from '../config/featureConfig';
    import { Operators } from '@/utils/constants';
    import { accountPropertyValidator } from '@/utils/providerAccountPropertyValidator';

    // components
    import AppHeader from '../components/AppHeader.vue';
    import AppButton, { Icons, Colors } from '../components/AppButton.vue';
    import AppInput from '../components/AppInput.vue';
    import { AlertTypes } from '../components/Notification.vue';

    const { VUE_APP_CHR_DOMAIN } = process.env;
    const PENDING_ACTIVATION_ORDER = 2;

    export default {
        name: 'AccountDetails',
        components: {
            AppHeader,
            AppButton,
            AppInput,
        },
        props: {
            accountId: {
                required: true,
                type: String,
            },
        },
        data() {
            return {
                orderId: '',
                orderName: '',
                provisioingStarted: false,
                taskId: null,
                buttonDisabled: false,
                Icons,
                Colors,
                isAccountPropertyValid: false,
            };
        },
        computed: {
            ...mapGetters([
                'getUserName',
                'getOperator',
                'getChrIntegrationAccountId',
                'getChrAccountId',
                'getServiceId',
                'getOntId',
                'getRouterIds',
                'getExternalTaskId',
                'getAccountData',
            ]),
            name() {
                return this.getUserName;
            },
            routerIdsTitle() {
                return (this?.getRouterIds?.length > 1 && this.getRouterIds?.[0] !== '-') ? 'Router IDs' : 'Router ID';
            },
            provisioningEnabled() {
                return this.isUbiquity || (!this.provisioingStarted && this.getServiceId && this.getOntId);
            },
            externalTaskId() {
                return this.taskId || this.getExternalTaskId;
            },
            detailsTitle() {
                return this.isUbiquity ? 'Details (Ubiquity)' : 'Details';
            },
            isUbiquity() {
                return featureFlag.isUbiquity();
            },
            isPilot() {
                const operator = this.getOperator;
                return operator === Operators.pilot;
            },
            completeOrderButtonDisabled() {
                return !this.isAccountPropertyValid || this.buttonDisabled;
            },
            formattedServiceId() {
                return this.formatValueForShowing(this.getServiceId);
            },
            formattedOntId() {
                return this.formatValueForShowing(this.getOntId);
            },
        },
        async created() {
            this.getUserOrder();
            if (!this.accountData) {
                const result = await getAccount(this.accountId);
                this.setAccountData(result.data);
            }
            this.checkRequiredFields();
        },
        methods: {
            ...mapActions([
                'setAccountData',
            ]),
            formatValueForShowing(value) {
                return value || '-';
            },
            goToSearch() {
                this.$router.push({ name: 'search' });
            },
            goToBuilder() {
                window.open(`${VUE_APP_CHR_DOMAIN}/Assignment/Account/${this.getChrIntegrationAccountId}`);
            },
            goToCPEView() {
                window.open(`${VUE_APP_CHR_DOMAIN}/ng/cpe/ev/${this.getChrAccountId}`);
            },
            checkRequiredFields() {
                const { errorMessage, isValid } = accountPropertyValidator(
                    this.getOperator,
                    this.getAccountData.properties,
                );
                this.isAccountPropertyValid = isValid;
                if (!this.isAccountPropertyValid) {
                    this.$eventBus.emit('showAlert', {
                        message: errorMessage,
                    });
                }
            },
            async onProvisionClick() {
                const taskId = await this.startProvisioning();
                if (taskId) {
                    await this.$store.dispatch('updateResourceMap', { taskId });
                }
            },
            async onCheckProvisionClick() {
                try {
                    this.$Progress.start();
                    const response = await checkProvisionStatus(this.accountId);
                    this.$Progress.finish();
                    const provisioningStatus = response.data.provisioning_status;
                    const alertPayload = this.getAlertPayloadByProvisionStatus(provisioningStatus);
                    this.$eventBus.emit('showAlert', alertPayload);
                } catch (error) {
                    this.$Progress.fail();
                    let errorMessage;
                    if (error.response.status === 500) {
                        errorMessage = 'Server error. Please try again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Authorization error. Please contact the T-Mobile Fiber team.';
                    } else if (error.response.status === 400) {
                        errorMessage = 'Please ensure that you have provided the correct ONT FSAN and Service ID for this customer.';
                    }
                    this.$eventBus.emit('showAlert', {
                        message: errorMessage,
                    });
                }
            },
            onCompleteOrderClick() {
                const confirmButton = {
                    label: 'Complete Order',
                    iconClass: '',
                    colorScheme: Colors.whiteYellow,
                    id: 'order-confirm-button',
                };

                this.$eventBus.emit('showAlert', {
                    message: 'Are you sure you would like to mark this order as filled?',
                    type: AlertTypes.warning,
                    buttons: [confirmButton],
                });

                this.$eventBus.once('buttonClicked', async id => {
                    if (id?.[0] === confirmButton.id) {
                        try {
                            this.$Progress.start();
                            this.buttonDisabled = true;

                            await activateOrder(this.accountId);
                            this.$Progress.finish();
                            this.$eventBus.emit('showAlert', {
                                message: 'Order Successfully Activated',
                                type: AlertTypes.success,
                            });

                            setTimeout(() => {
                                this.buttonDisabled = false;
                                this.$router.push({ name: 'search' });
                            }, 2000);
                        } catch (error) {
                            this.$Progress.fail();
                            let errorMessage;
                            if (error.response.status === 500) {
                                errorMessage = 'Server error. Please try again.';
                            } else if (error.response.status === 403) {
                                errorMessage = 'Authorization error. Please contact LotusFlare.';
                            } else if (error.response.status === 400) {
                                errorMessage = 'System error, please contact LotusFlare';
                            }
                            this.$eventBus.emit('showAlert', {
                                message: errorMessage,
                            });
                            this.buttonDisabled = false;
                        }
                    }
                });
            },
            async startProvisioning() {
                try {
                    this.$Progress.start();
                    const response = await startProvision(this.accountId);
                    this.provisioingStarted = true;
                    this.$Progress.finish();
                    this.taskId = response.data.external_task_id;
                    this.$eventBus.emit('showAlert', {
                        message: 'Provisioning started successfully',
                        type: AlertTypes.success,
                    });
                    return response.data.external_task_id;
                } catch (error) {
                    this.$Progress.fail();
                    let errorMessage;
                    if (error.response.status === 500) {
                        errorMessage = 'Server error. Please try again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Authorization error. Please contact the T-Mobile Fiber team.';
                    } else if (error.response.status === 400) {
                        errorMessage = 'Please ensure that you have provided the correct ONT FSAN and Service ID for this customer.';
                    }
                    this.$eventBus.emit('showAlert', {
                        message: errorMessage,
                    });
                    return null;
                }
            },
            onEditAccountClick() {
                this.$router.push({ name: 'editAccount' });
            },
            async getUserOrder() {
                try {
                    this.$Progress.start();
                    const response = await getOrderEntities(this.accountId);
                    const orderId = Object.values(response.data.orders).find(
                        order => order.state === PENDING_ACTIVATION_ORDER,
                    )?.order_id;
                    if (!orderId) {
                        this.buttonDisabled = true;
                        throw new Error('No active orders found for the specified account.');
                    } else {
                        this.buttonDisabled = false;
                        this.orderId = orderId;
                        this.orderName = Object.values(response.data.orders[orderId].entities?.offer)?.[0]?.entity_name;
                    }
                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    let errorMessage;
                    if (!error.response) {
                        errorMessage = error;
                    } else if (error.response.status === 500) {
                        errorMessage = 'Server error. Please try again.';
                    } else if (error.response.status === 403) {
                        errorMessage = 'Authorization error. Please contact LotusFlare.';
                    } else if (error.response.status === 400) {
                        errorMessage = 'System error, please contact LotusFlare';
                    }
                    this.$eventBus.emit('showAlert', {
                        message: errorMessage,
                    });
                }
            },
            getAlertPayloadByProvisionStatus(status) {
                const provisionStatusToAlertPayload = new Map([
                    [0, { message: 'Provisioning status unknown, please contact the T-Mobile Fiber team', type: AlertTypes.info }],
                    [1, { message: 'Provisioning in progress', type: AlertTypes.warning }],
                    [2, { message: 'Provisioning in progress', type: AlertTypes.warning }],
                    [100, { message: 'Provisioning successful', type: AlertTypes.success }],
                    [200, { message: 'Provisioning failed, please contact the T-Mobile Fiber team', type: AlertTypes.error }],
                    [300, { message: 'Rollback success', type: AlertTypes.success }],
                    [301, { message: 'Rollback failed', type: AlertTypes.error }],
                    [400, { message: 'Bypassed', type: AlertTypes.info }],
                    [500, { message: 'Cancelled', type: AlertTypes.info }],
                ]);

                return provisionStatusToAlertPayload.get(status);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/colors';
    @import '../assets/scss/palette';
    .breadcrumbs-wrapper {
        display: flex;
        margin-bottom: 24px;

        .breadcrumbs-item {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            font-weight: 600;
            color: $blue;
            padding-right: 30px;
            cursor: pointer;

            &:after {
                position: absolute;
                right: 4px;
                top: 5px;
                content: "";
                background: url("../assets/icons/icon-arrow-right.svg");
                width: 16px;
                height: 16px;
            }

            &.active {
                opacity: 0.5;
                padding-right: 8px;
                cursor: auto;

                &:after {

                    background: none;
                }
            }
        }
    }

    section {
        margin-bottom: 38px;

        .section-title {
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }

        .section-content {
            padding: 8px 16px 12px;
            border-radius: 8px;
            background-color: $white;
            display: grid;
            grid-template-columns: 95px 1.5fr;
            grid-template-rows:  1fr minmax(0, 2fr);
            grid-gap: 1rem;

            &.provision-section {
                padding: 24px;
                display: block;

                .provision-section-text {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.75;
                    color: #8597bf;
                    text-transform: uppercase;
                    margin-bottom: 16px;
                    &__with-margin {
                        margin-top: 25px;
                    }
                }
            }

            .content-value {
                word-wrap: break-word;
                word-break: break-all;
            }
        }

        .content-key {
            font-size: 12px;
            font-weight: 600;
        }

        .content-value {
            font-size: 14px;
            font-weight: 400;
        }

        .account-id-for-order {
            margin-block: 16px;
        }
    }
</style>
